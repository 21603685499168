import { ChangeEvent, ForwardedRef, forwardRef, useRef, useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import { useIntl } from 'react-intl';

import BaseButton from 'src/domains/root/commons/buttons/Base';
import { SelectBoxArrowIcon } from 'src/domains/root/commons/icons';
import { HeadTitle } from 'src/shared/ui/HeadTitle';
import { InputField } from 'src/shared/ui/InputField';
import PeriodInput, { type Period } from './PeriodInput';
import PeriodDialog from './PeriodDialog';
import { GATEWAYS_ALL } from './ReportList';

export function ReportListSearchCondition({
  isSearchDisabledByNoReport,
  initialPeriod,
  period,
  isPeriodDirty,
  checkPeriodDirty,
  handlePeriodChange,
  initialGatewayNames,
  selectedGatewayName,
  changeGatewayName,
  searchTitle,
  changeSearchTitle,
  isClearButtonEnabled,
  clearSearchCondition,
}: {
  isSearchDisabledByNoReport: boolean;
  initialPeriod: Period;
  period: Period;
  isPeriodDirty: boolean;
  checkPeriodDirty: () => void;
  handlePeriodChange: (props: Period) => void;
  initialGatewayNames: string[];
  selectedGatewayName: string;
  changeGatewayName: (gatewayName: string) => void;
  searchTitle: string;
  changeSearchTitle: (title: string) => void;
  isClearButtonEnabled: boolean;
  clearSearchCondition: () => void;
}) {
  const intl = useIntl();

  const [openPeriodDialog, setOpenPeriodDialog] = useState(false);

  const gatewayNames = initialGatewayNames;

  const handleGatewayNameChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;
    changeGatewayName(value);
  };

  const togglePeriodDialog = () => {
    setOpenPeriodDialog((prev) => !prev);
  };

  const handleSearchTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    changeSearchTitle(e.target.value);
  };

  const handleClearSearchTitle = () => {
    changeSearchTitle('');
  };

  return (
    <Box px={{ xs: 2, sm: 0 }}>
      <Box display="flex" columnGap={2}>
        <Box py={0.5}>
          <HeadTitle>
            {intl.formatMessage({ id: 'pages.Reports.searchCondition.title' })}
          </HeadTitle>
        </Box>
        <ClearButton
          disabled={!isClearButtonEnabled}
          clear={clearSearchCondition}
        />
      </Box>
      <Box
        pt={2.5}
        display="flex"
        flexDirection={{ xs: 'column', lg: 'row' }}
        gap={2}
      >
        <Box width={{ sm: 328 }} minWidth={{ sm: 328, lg: 244 }}>
          <PeriodInput
            disabled={isSearchDisabledByNoReport}
            label={intl.formatMessage({
              id: 'pages.Reports.searchCondition.period',
            })}
            period={period}
            initialValue={intl.formatMessage({
              id: 'pages.Reports.searchCondition.periodAll',
            })}
            isDirty={isPeriodDirty}
            toggleDialog={togglePeriodDialog}
          />
        </Box>

        <PeriodDialog
          loggingId="PeriodDialog"
          open={openPeriodDialog}
          title={intl.formatMessage({
            id: 'pages.Reports.searchCondition.periodDialog.title',
          })}
          content={<Typography color="#141414"></Typography>}
          initialPeriod={initialPeriod}
          isMinLimited
          period={period}
          onConfirm={handlePeriodChange}
          onDirty={checkPeriodDirty}
          confrimText={intl.formatMessage({
            id: 'pages.Reports.searchCondition.periodDialog.searchButton',
          })}
          close={togglePeriodDialog}
        />

        <SelectFormControl disabled={isSearchDisabledByNoReport}>
          <SelectInputLabel htmlFor="gatewayName">
            {intl.formatMessage({
              id: 'pages.Reports.searchCondition.gatewayName',
            })}
          </SelectInputLabel>
          <StyledSelect
            value={selectedGatewayName}
            onChange={handleGatewayNameChange}
            labelId="gatewayName"
            label={intl.formatMessage({
              id: 'pages.Reports.searchCondition.gatewayName',
            })}
            name="gatewayName"
          >
            <SelectMenuItem value={GATEWAYS_ALL}>
              <MenuItemText>
                {intl.formatMessage({
                  id: 'pages.Reports.searchCondition.gatewayNameAll',
                })}
              </MenuItemText>
            </SelectMenuItem>
            {gatewayNames.map((gatewayName) => (
              <SelectMenuItem key={gatewayName} value={gatewayName}>
                <MenuItemText>{gatewayName}</MenuItemText>
              </SelectMenuItem>
            ))}
          </StyledSelect>
        </SelectFormControl>
        <InputField
          disabled={isSearchDisabledByNoReport}
          label={intl.formatMessage({
            id: 'pages.Reports.searchCondition.reportTitle',
          })}
          placeholder={intl.formatMessage({
            id: 'pages.Reports.searchCondition.reportTitlePlaceholder',
          })}
          value={searchTitle}
          onChange={handleSearchTitleChange}
          InputProps={{
            endAdornment: searchTitle && (
              <CancelOutlined
                color="primary"
                onClick={handleClearSearchTitle}
                sx={{ cursor: 'pointer' }}
              />
            ),
          }}
          sx={{
            backgroundColor: isSearchDisabledByNoReport
              ? 'transparent'
              : 'common.white',
            borderRadius: 1,
            maxWidth: { sm: 328, lg: '100%' },

            '& .MuiInputBase-root': {
              '& fieldset': {
                borderColor: '#828282',
              },
            },

            '& .MuiInputBase-input': {
              height: 28, // content-box, py: 8.5px + height: 28px = 45px
            },

            '& .MuiInputLabel-root': {
              color: '#141414',
            },
          }}
        />
      </Box>
    </Box>
  );
}

function ClearButton({
  disabled,
  clear,
}: {
  disabled: boolean;
  clear: () => void;
}) {
  const intl = useIntl();

  return (
    <BaseButton
      variant="outlined"
      disabled={disabled}
      onClick={clear}
      sx={{
        px: 2.75,
        py: 7 / 8,

        backgroundColor: 'common.white',
        borderColor: 'primary.main',
        color: 'primary.main',

        '&:hover': {
          backgroundColor: 'primary.main',
          borderColor: 'primary.main',
          color: 'common.white',
        },

        '&:disabled': {
          backgroundColor: 'transparent',
          borderColor: 'primary.main',
          color: 'primary.main',
        },
      }}
    >
      {intl.formatMessage({
        id: 'pages.Reports.searchCondition.clearButton',
      })}
    </BaseButton>
  );
}

function SelectFormControl({
  disabled,
  children,
}: {
  disabled: boolean;
  children: React.ReactNode;
}) {
  return (
    <FormControl
      disabled={disabled}
      variant="outlined"
      sx={{
        height: 45,
        borderRadius: 1,
        width: { lg: '100%' },
        maxWidth: { sm: 328, lg: '100%' },
        backgroundColor: disabled ? 'transparent' : 'common.white',
      }}
    >
      {children}
    </FormControl>
  );
}

function SelectInputLabel({
  htmlFor,
  children,
}: {
  htmlFor: string;
  children: React.ReactNode;
}) {
  return (
    <InputLabel
      htmlFor={htmlFor}
      sx={{
        fontSize: 12,
        color: '#141414',

        // muiがscale(0.75)に設定したせいでカスタムしづらい、のでscale(1)にする
        transform: 'translate(14px, -7px) scale(1)',
      }}
    >
      {children}
    </InputLabel>
  );
}

function StyledSelect({
  value,
  onChange,
  labelId,
  label,
  name,
  children,
}: {
  value: string;
  onChange: (event: SelectChangeEvent<unknown>) => void;
  labelId: string;
  label: string;
  name: string;
  children: React.ReactNode;
}) {
  const selectRef = useRef<HTMLDivElement | null>(null);
  const [menuWidth, setMenuWidth] = useState<number | null>(null);

  const handleOpen = () => {
    if (selectRef.current) {
      setMenuWidth(selectRef.current.clientWidth);
    }
  };
  return (
    <Select
      ref={selectRef}
      value={value}
      onChange={onChange}
      onOpen={handleOpen}
      labelId={labelId}
      label={label}
      IconComponent={SelectBoxArrowIcon}
      inputProps={{
        name: name,
        id: labelId,
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            width: menuWidth,
          },
        },
      }}
      sx={{
        height: 45,

        fieldset: {
          border: '1px solid #828282',

          '& > legend': {
            fontSize: 12,
          },
        },
      }}
    >
      {children}
    </Select>
  );
}

const SelectMenuItem = forwardRef<
  HTMLLIElement,
  { value: string; children?: React.ReactNode }
>(
  (
    props: { value: string; children?: React.ReactNode },
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    const { children, ...rest } = props;

    return (
      <MenuItem
        {...rest}
        ref={ref}
        sx={{
          p: 1,
          mx: 1,
          borderRadius: 1,
          color: '#141414',

          ':hover': {
            backgroundColor: 'primary.light',
          },

          ':focus-visible': {
            backgroundColor: 'primary.light',
          },

          '&.Mui-selected': {
            backgroundColor: 'primary.main',
            color: 'common.white',
            fontWeight: 'bold',

            ':hover': {
              backgroundColor: (theme) => `${theme.palette.primary.main}50`,
            },

            ':focus-visible': {
              backgroundColor: 'primary.main',
            },

            ':focus-visible:hover': {
              backgroundColor: (theme) => `${theme.palette.primary.main}50`,
            },
          },
        }}
      >
        {children}
      </MenuItem>
    );
  },
);

SelectMenuItem.displayName = 'SelectMenuItem';

function MenuItemText({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant="inherit" noWrap>
      {children}
    </Typography>
  );
}
