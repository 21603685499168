import { TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useState, ChangeEvent, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import BaseButton from 'src/domains/root/commons/buttons/Base';
import NegativeButton from 'src/domains/root/commons/buttons/Negative';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from 'src/domains/root/commons/dialog';
import { toYmd, fromUnixMs } from 'src/utils/time';
import { Period } from './PeriodInput';

const MIN_RANGE_DATE = new Date('2021-01-01T00:00:00.000+09:00');
const MAX_RANGE_DATE = new Date('2099-12-31T23:59:59.999+09:00');

interface PeriodDialogProps {
  open: boolean;
  title: string;
  content: React.ReactNode;
  closeText?: string;
  confrimText: string;
  loggingId: string;
  initialPeriod: Period;
  period: Period;
  isMinLimited?: boolean;
  isPeriodLimited?: boolean;
  onConfirm: (props: Period) => void;
  onDirty: () => void;
  close: () => void;
}

export default function PeriodDialog(props: PeriodDialogProps) {
  const {
    open,
    title,
    content,
    closeText,
    confrimText,
    loggingId,
    initialPeriod,
    period,
    isMinLimited,
    isPeriodLimited,
    onConfirm,
    onDirty,
    close,
  } = props;

  const intl = useIntl();

  const [localPeriod, setLocalPeriod] = useState(period);
  const [isPeriodOrderError, setIsPeriodOrderError] = useState(false);
  const [isPeriodLimitError, setIsPeriodLimitError] = useState(false);
  const [isPeriodBeginAtRangeError, setIsPeriodBeginAtRangeError] =
    useState(false);
  const [isPeriodEndAtRangeError, setIsPeriodEndAtRangeError] = useState(false);

  const min = toYmd(fromUnixMs(initialPeriod.beginAt));
  const max = toYmd(fromUnixMs(initialPeriod.endAt));

  const beginDate = toYmd(fromUnixMs(localPeriod.beginAt));
  const endDate = toYmd(fromUnixMs(localPeriod.endAt));

  const isAnyError =
    isPeriodOrderError ||
    isPeriodLimitError ||
    isPeriodBeginAtRangeError ||
    isPeriodEndAtRangeError;

  const handleBeginAtChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newBeginAt = fromUnixMs(new Date(event.target.value || min).getTime())
      .startOf('day')
      .toMillis();

    setLocalPeriod((prev) => ({
      ...prev,
      beginAt: newBeginAt,
    }));
  };

  const handleEndAtChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newEndAt = fromUnixMs(new Date(event.target.value || max).getTime())
      .endOf('day')
      .toMillis();

    setLocalPeriod((prev) => ({
      ...prev,
      endAt: newEndAt,
    }));
  };

  const handleConfirm = () => {
    onConfirm({ beginAt: localPeriod.beginAt, endAt: localPeriod.endAt });
    onDirty();
    close();
  };

  const checkPeriodErrors = useCallback(
    (beginAt: number, endAt: number) => {
      const isOrderError =
        fromUnixMs(beginAt).startOf('day') > fromUnixMs(endAt).startOf('day');

      const isLimitError =
        !!isPeriodLimited &&
        (() => {
          const beginAtDate = fromUnixMs(beginAt);
          const endAtDate = fromUnixMs(endAt);

          //「うるう年の2月29日」の場合は、終了日を開始日の1年＋1日（2月28日）までにする
          if (beginAtDate.month === 2 && beginAtDate.day === 29) {
            return endAtDate.diff(beginAtDate, 'days').days > 366;
          }

          return endAtDate.diff(beginAtDate, 'years').years > 1;
        })();

      const isBeginAtRangeError =
        !!isPeriodLimited && new Date(beginAt) < MIN_RANGE_DATE;
      const isEndAtRangeError =
        !!isPeriodLimited && new Date(endAt) > MAX_RANGE_DATE;

      return {
        isOrderError,
        isLimitError,
        isBeginAtRangeError,
        isEndAtRangeError,
      };
    },
    [isPeriodLimited],
  );

  useEffect(() => {
    setLocalPeriod(period);
  }, [period]);

  useEffect(() => {
    const {
      isOrderError,
      isLimitError,
      isBeginAtRangeError,
      isEndAtRangeError,
    } = checkPeriodErrors(localPeriod.beginAt, localPeriod.endAt);

    setIsPeriodOrderError(isOrderError);
    setIsPeriodLimitError(isLimitError);
    setIsPeriodBeginAtRangeError(isBeginAtRangeError);
    setIsPeriodEndAtRangeError(isEndAtRangeError);
  }, [localPeriod.beginAt, localPeriod.endAt, checkPeriodErrors]);

  return (
    <Dialog open={open} fullWidth maxWidth="sm" loggingId={loggingId}>
      <DialogTitle onClose={close}>{title}</DialogTitle>
      <DialogContent sx={{ py: 3 }}>
        {content}
        <Box
          pt={3.5}
          display="grid"
          gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
          gap={3}
        >
          <TextField
            type="date"
            name="begin"
            value={beginDate}
            onChange={handleBeginAtChange}
            size="small"
            label={intl.formatMessage({
              id: 'pages.Reports.searchCondition.periodDialog.start',
            })}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: isPeriodLimited
                  ? MIN_RANGE_DATE
                  : isMinLimited
                    ? min
                    : undefined,
                max: endDate,
              },
              sx: { pr: 1 },
            }}
            helperText={
              isPeriodBeginAtRangeError
                ? intl.formatMessage({
                    id: 'pages.Reports.searchCondition.periodDialog.startRangeError',
                  })
                : isPeriodLimitError
                  ? intl.formatMessage({
                      id: 'pages.Reports.searchCondition.periodDialog.startLimitError',
                    })
                  : isPeriodOrderError
                    ? intl.formatMessage({
                        id: 'pages.Reports.searchCondition.periodDialog.startOrderError',
                      })
                    : null
            }
            error={isAnyError}
          />
          <TextField
            type="date"
            name="end"
            value={endDate}
            onChange={handleEndAtChange}
            size="small"
            label={intl.formatMessage({
              id: 'pages.Reports.searchCondition.periodDialog.end',
            })}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              inputProps: {
                min: beginDate,
                max,
              },
              sx: { pr: 1 },
            }}
            helperText={
              isPeriodEndAtRangeError
                ? intl.formatMessage({
                    id: 'pages.Reports.searchCondition.periodDialog.endRangeError',
                  })
                : isPeriodLimitError
                  ? intl.formatMessage({
                      id: 'pages.Reports.searchCondition.periodDialog.endLimitError',
                    })
                  : isPeriodOrderError
                    ? intl.formatMessage({
                        id: 'pages.Reports.searchCondition.periodDialog.endOrderError',
                      })
                    : null
            }
            error={isAnyError}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          p: 3.5,
          flexDirection: {
            xs: 'column-reverse',
            sm: 'row',
          },
          gap: 2,
          '& > :not(:first-of-type)': {
            ml: 0,
          },
        }}
      >
        <NegativeButton
          onClick={close}
          sx={{ width: { xs: '100%', sm: 'unset' } }}
        >
          {closeText ?? intl.formatMessage({ id: 'common.cancel' })}
        </NegativeButton>
        <BaseButton
          variant="contained"
          disabled={isAnyError}
          onClick={handleConfirm}
          sx={{
            px: 3,
            py: 7 / 8,
            backgroundColor: 'primary.main',
            color: 'common.white',
            boxShadow: 'none',
            width: { xs: '100%', sm: 'unset' },
            minWidth: 'unset',

            '&:hover': {
              backgroundColor: 'nito.main',
              color: 'common.white',
              boxShadow: 'none',
            },

            '&:disabled': {
              backgroundColor: 'primary.main',
              color: 'common.white',
            },
          }}
        >
          {confrimText}
        </BaseButton>
      </DialogActions>
    </Dialog>
  );
}
