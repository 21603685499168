import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { InputField } from 'src/shared/ui/InputField';
import { useState } from 'react';
import * as API from 'src/apis';
import { DateTime } from 'luxon';
import * as yup from 'yup';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { fromUnixMs } from 'src/utils/time';
import { useDemoSiteSnackbar } from 'src/domains/root/commons/snackbar';
import LoadingOverlay from 'src/domains/root/commons/LoadingOverlay';
import { IS_ENV } from 'src/constants';
import { Text } from 'src/shared/ui';
import { BreadcrumbsV3, BreadcrumbTitleV3 } from '../../commons/breadcrumbs';
import NegativeButton from '../../commons/buttons/Negative';
import PrimaryButton from '../../commons/buttons/Primary';
import { DialogTitleAlert, InfoDialog } from '../../commons/dialog';
import { usePostWorkspacesReports } from './usePostWorkspacesReports';
import PeriodInput, { Period } from './PeriodInput';
import PeriodDialog from './PeriodDialog';

const validationSchema = yup.object().shape({
  title: yup.string().required().min(1).max(50),
  beginAt: yup.string().required().min(1),
  endAt: yup.string().required().min(1),
  gatewayIds: yup.array(yup.string().required()).required(),
});

interface ReportsMakeNewProps {
  gateways: API.WorkspaceGatewaysSensorDataBeginEndTimestamp[];
  createReport: ReturnType<typeof usePostWorkspacesReports>;
}

export default function ReportsMakeNew({
  gateways,
  createReport,
}: ReportsMakeNewProps) {
  return (
    <Box display="flex" flexDirection="column" rowGap={3.5}>
      <BreadCrumb />
      <NewReport gateways={gateways} createReport={createReport} />
    </Box>
  );
}

function BreadCrumb() {
  const intl = useIntl();

  return (
    <Box
      pl={{
        xs: 2,
        sm: 0,
      }}
    >
      <BreadcrumbsV3>
        <Link to="/reports">
          <BreadcrumbTitleV3 color="primary">
            {intl.formatMessage({
              id: 'pages.Reports.New.parentTitle',
            })}
          </BreadcrumbTitleV3>
        </Link>
        <BreadcrumbTitleV3>
          {intl.formatMessage({
            id: 'pages.Reports.New.title',
          })}
        </BreadcrumbTitleV3>
      </BreadcrumbsV3>
    </Box>
  );
}

function NewReport({
  gateways,
  createReport,
}: {
  gateways: API.WorkspaceGatewaysSensorDataBeginEndTimestamp[];
  createReport: ReturnType<typeof usePostWorkspacesReports>;
}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { enqueueDemoSiteSnackbar } = useDemoSiteSnackbar();

  const initialPeriod = {
    beginAt: DateTime.now().startOf('day').minus({ months: 3 }).toMillis(),
    endAt: DateTime.now().endOf('day').toMillis(),
  };

  const [period, setPeriod] = useState({
    beginAt: initialPeriod.beginAt,
    endAt: initialPeriod.endAt,
  });

  const [openPeriodDialog, setOpenPeriodDialog] = useState(false);
  const [isPeriodDirty, setIsPeriodDirty] = useState(false);

  const [openCreateReportSuccessDialog, setOpenCreateReportSuccessDialog] =
    useState(false);
  const [openCreateReportFailDialog, setOpenCreateReportFailDialog] =
    useState(false);

  const closeCreateReportSuccessDialog = () => {
    navigate('/reports');
  };

  const toggleCreateReportFailDialog = () => {
    setOpenCreateReportFailDialog((prev) => !prev);
  };

  const initialValues = {
    title: '',
    beginAt: '',
    endAt: '',
    gatewayIds: [gateways[0]?.deviceId],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      if (IS_ENV.DEMO) {
        enqueueDemoSiteSnackbar();
        return;
      }

      try {
        await createReport.mutate(values);

        setOpenCreateReportSuccessDialog(true);
      } catch (error) {
        toggleCreateReportFailDialog();
      }
    },
    enableReinitialize: true,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
  });

  const handlePeriodChange = ({ beginAt, endAt }: Period) => {
    setPeriod({ beginAt, endAt });

    const beginAtISO = fromUnixMs(beginAt).toISO();
    const endAtISO = fromUnixMs(endAt).toISO();

    if (!beginAtISO || !endAtISO) {
      // eslint-disable-next-line no-console
      console.error(
        `Non expected data type found. beginAt: ${beginAt}, beginAtISO: ${beginAtISO}, endAt: ${endAt}, endAtISO: ${endAtISO}`,
      );

      navigate('/errors/unknown-error');
      return;
    }

    formik.setValues({
      ...formik.values,
      beginAt: beginAtISO,
      endAt: endAtISO,
    });
  };

  const togglePeriodDialog = () => {
    setOpenPeriodDialog((prev) => !prev);
  };

  const checkPeriodDirty = () => {
    setIsPeriodDirty(true);
  };

  const handleFocusTitle = () => {
    formik.setTouched({ ...formik.touched, title: true });
  };

  return (
    <Box
      bgcolor="common.white"
      borderRadius={1}
      border={{ xs: 'none', sm: '1px solid #828282' }}
      p={{ xs: 2, sm: 3 }}
    >
      <Box pb={4}>
        <HeadTitle>
          {intl.formatMessage({
            id: 'pages.Reports.New.headTitle',
          })}
        </HeadTitle>
      </Box>
      <FormikProvider value={formik}>
        <Box display="flex" flexDirection="column" rowGap={4}>
          <InputField
            label={intl.formatMessage({
              id: 'pages.Reports.New.reportTitle.label',
            })}
            placeholder={intl.formatMessage({
              id: 'pages.Reports.New.reportTitle.placeholder',
            })}
            helperText={intl.formatMessage({
              id: 'pages.Reports.New.reportTitle.helperText',
            })}
            name="title"
            onFocus={handleFocusTitle}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title}
            error={formik.touched.title && Boolean(formik.errors.title)}
            sx={{
              backgroundColor: 'common.white',
              borderRadius: 1,

              '& .MuiInputBase-root': {
                '& fieldset': {
                  borderColor: '#828282',
                },
              },

              '& .MuiInputBase-input': {
                height: 28, // content-box, py: 8.5px + height: 28px = 45px
              },

              '& .MuiInputLabel-root': {
                color: '#141414',
              },
            }}
          />

          <Box width={{ sm: 286 }} minWidth={{ sm: 286, lg: 385 }}>
            <PeriodInput
              label={intl.formatMessage({
                id: 'pages.Reports.New.period.label',
              })}
              placeholder={intl.formatMessage({
                id: 'pages.Reports.New.period.placeholder',
              })}
              helperText={intl.formatMessage({
                id: 'pages.Reports.New.period.helperText',
              })}
              period={period}
              isDirty={isPeriodDirty}
              toggleDialog={togglePeriodDialog}
            />

            <PeriodDialog
              loggingId="PeriodDialog"
              open={openPeriodDialog}
              title={intl.formatMessage({
                id: 'pages.Reports.searchCondition.periodDialog.title',
              })}
              content={
                <Typography color="#141414">
                  {intl.formatMessage({
                    id: 'pages.Reports.New.period.dialogContent',
                  })}
                </Typography>
              }
              initialPeriod={initialPeriod}
              period={period}
              isPeriodLimited
              onConfirm={handlePeriodChange}
              onDirty={checkPeriodDirty}
              confrimText={intl.formatMessage({
                id: 'pages.Reports.New.period.dialogConfirmButton',
              })}
              close={togglePeriodDialog}
            />
          </Box>

          <TargetGateway gateways={gateways} />

          <Box>
            <Text>
              {intl.formatMessage(
                {
                  id: 'pages.Reports.New.createTimeInformation',
                },
                {
                  time: (
                    <strong>
                      {intl.formatMessage({
                        id: 'pages.Reports.New.createTime',
                      })}
                    </strong>
                  ),
                },
              )}
            </Text>
          </Box>

          <Box display="flex" columnGap={2} justifyContent="flex-end">
            <BackButton />
            <MakeNewButton handleClick={formik.handleSubmit} />
          </Box>
        </Box>

        <LoadingOverlay open={formik.isSubmitting} />

        <InfoDialog
          open={openCreateReportSuccessDialog}
          title={intl.formatMessage({
            id: 'pages.Reports.New.successDialog.title',
          })}
          titleAlert={
            <DialogTitleAlert
              status="succeeded"
              message={{
                success: intl.formatMessage({
                  id: 'pages.Reports.New.successDialog.titleAlert',
                }),
              }}
            />
          }
          content={
            <Box>
              <Text>
                {intl.formatMessage(
                  {
                    id: 'pages.Reports.New.successDialog.content.creationTime',
                  },
                  {
                    time: (
                      <strong>
                        {intl.formatMessage({
                          id: 'pages.Reports.New.successDialog.content.time',
                        })}
                      </strong>
                    ),
                  },
                )}
              </Text>
              <Text>
                {intl.formatMessage({
                  id: 'pages.Reports.New.successDialog.content.waitMessage',
                })}
              </Text>
            </Box>
          }
          loggingId="ReportsMakeNewSuccessDialog"
          close={closeCreateReportSuccessDialog}
        />
        <InfoDialog
          open={openCreateReportFailDialog}
          title={intl.formatMessage({
            id: 'pages.Reports.New.failDialog.title',
          })}
          content={
            <Alert severity="error">
              {intl.formatMessage({
                id: `pages.Reports.New.failDialog.content.${createReport.status === 'hasError' ? createReport.errorCode : 'default'}`,
              })}
            </Alert>
          }
          loggingId="ReportsMakeNewFailDialog"
          close={toggleCreateReportFailDialog}
        />
      </FormikProvider>
    </Box>
  );
}

function TargetGateway({
  gateways,
}: {
  gateways: API.WorkspaceGatewaysSensorDataBeginEndTimestamp[];
}) {
  const intl = useIntl();
  const formik = useFormikContext<{ gatewayIds: string[] }>();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const gatewayId = event.target.value;
    formik.setFieldValue('gatewayIds', [gatewayId]);
  };

  return (
    <Box>
      <Box pb={2}>
        <Typography
          fontSize={{ xs: 13, sm: 18 }}
          fontWeight={700}
          color="#141414"
        >
          {intl.formatMessage({
            id: 'pages.Reports.New.targetGateway.title',
          })}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        rowGap={1.5}
        sx={{ wordBreak: 'break-all' }}
      >
        <RadioGroup
          aria-label="TargetGateway"
          name="TargetGateway"
          value={formik.values.gatewayIds[0]}
          onChange={handleChange}
        >
          {gateways.length === 0 ? (
            <Alert severity="info">
              {intl.formatMessage({
                id: 'pages.Reports.New.noData',
              })}
            </Alert>
          ) : (
            gateways.map((gateway) => (
              <FormControlLabel
                key={gateway.deviceId}
                value={gateway.deviceId}
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: 'primary.main',
                      '& .MuiSvgIcon-root': { width: 18, height: 18 },
                    }}
                  />
                }
                label={
                  <Typography fontSize={{ xs: 13, sm: 16 }}>
                    {gateway.name}
                  </Typography>
                }
              />
            ))
          )}
        </RadioGroup>
      </Box>
    </Box>
  );
}

function BackButton() {
  const intl = useIntl();

  return (
    <Link to="/reports" tabIndex={-1}>
      <NegativeButton>
        {intl.formatMessage({
          id: 'pages.Reports.New.backButton',
        })}
      </NegativeButton>
    </Link>
  );
}

function MakeNewButton({ handleClick }: { handleClick: () => void }) {
  const intl = useIntl();

  const formik = useFormikContext();

  return (
    <PrimaryButton
      disabled={!formik.isValid || formik.isSubmitting}
      onClick={handleClick}
    >
      {intl.formatMessage({
        id: 'pages.Reports.New.makeNewButton',
      })}
    </PrimaryButton>
  );
}

function HeadTitle({ children }: { children: React.ReactNode }) {
  return (
    <Typography
      pl={1.5}
      fontSize={18}
      fontWeight="bold"
      borderLeft={(props) => `5px solid ${props.palette.nito.main}`}
    >
      {children}
    </Typography>
  );
}
