import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Badge from '@mui/material/Badge';
import styled, { css } from 'styled-components/macro';
import theme from 'src/domains/root/styles/theme';
import { Box } from '@mui/material';
import useSmallScreen from 'src/domains/root/hooks/use-small-screen';
import _ReportIcon from '@mui/icons-material/InsertDriveFileRounded';
import HomeIcon from './icons/HomeIcon';
import CurrentAlertIcon from './icons/CurrentAlertIcon';
import AlertHistoryIcon from './icons/AlertHistoryIcon';
import SettingIcon from './icons/SettingIcon';
import OtherSettingIcon from './icons/OtherSettingIcon';

const emphasisStyle = css`
  & .MuiListItemIcon-root {
    & > svg {
      & > path {
        fill: white;
      }

      & > circle {
        fill: white;
      }
    }

    & .CurrentAlertIcon > path + path {
      fill: ${theme.palette.primary.main};
    }
  }

  & .MuiListItemText-root .MuiTypography-root {
    color: white;
    font-weight: bold;
  }

  & .MuiBadge-badge {
    background: white;
    color: ${theme.palette.nito.main};
  }
`;

const navLinkStyle = css`
  border-radius: 0 4px 4px 0;
  height: 40px;
  margin-bottom: 13px;
  padding-left: ${theme.spacing(2.5)};
  padding-right: ${theme.spacing(2.5)};

  &.active {
    background: ${theme.palette.primary.main};
    ${emphasisStyle}
  }

  &:hover {
    background: ${theme.palette.primary.light};
    ${emphasisStyle}
  }

  &.alert {
    & .MuiListItemText-root .MuiTypography-root {
      font-weight: bold;
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledMenuIcon = styled(({ drawerOpen, ...props }) => (
  <ListItemIcon {...props} />
))<{ drawerOpen: boolean }>`
  min-width: unset;
  padding-right: ${(props) =>
    props.drawerOpen ? theme.spacing(2) : theme.spacing(3)};
`;

interface StyledBadgeProps {
  alert: number;
  drawerOpen: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledBadge = styled(({ alert, drawerOpen, ...props }) => (
  <Badge color="primary" {...props} />
))<StyledBadgeProps>`
  & > span {
    color: white;
    font-size: 9px;
    font-weight: 700;
    height: 14px;
    min-width: ${(props) =>
      props.alert < 10 ? '14px' : props.alert > 99 ? '24px' : '20px'};
    right: ${(props) => {
      if (!props.drawerOpen) {
        if (props.alert < 10) {
          return '117px;';
        } else if (props.alert < 100) {
          return '114px;';
        } else {
          return '112px;';
        }
      } else if (props.alert < 10) {
        return '-11px;';
      } else if (props.alert < 100) {
        return '-14px;';
      } else {
        return '-17px;';
      }
    }};
    top: 11px;
    width: ${(props) =>
      props.alert < 10 ? '14px' : props.alert > 99 ? '24px' : '20px'};
  }
`;

const menuTypographyStyle = css`
  font-size: 1.125rem;
`;

interface MenuListProps {
  alert: number;
  drawerOpen: boolean;
  handleDrawerToggle?: () => void;
}

const MenuList: React.FC<MenuListProps> = (props) => {
  const { alert, drawerOpen, handleDrawerToggle } = props;
  const intl = useIntl();
  const isSmallScreen = useSmallScreen();
  const params = useParams();

  // この配列の順序でメニューリストが描画される
  const pageInfo = [
    { title: 'Home', path: '/', icon: HomeIcon, subPath: 'gateways' },
    {
      title: 'CurrentAlert',
      path: '/current-alert',
      icon: CurrentAlertIcon,
    },
    { title: 'AlertHistory', path: '/alert-history', icon: AlertHistoryIcon },
    {
      title: 'Reports',
      path: '/reports',
      icon: ReportIcon,
      hide: false,
    },
    { title: 'Setting', path: '/settings/sensor', icon: SettingIcon },
    { title: 'OtherSetting', path: '/settings/others', icon: OtherSettingIcon },
  ];

  const paramPath = params['*']?.split('/')[0];

  const closeWhenMobile = () => {
    if (handleDrawerToggle) handleDrawerToggle();
  };

  return (
    <Box pr={3.5}>
      {pageInfo
        .filter((page) => !page.hide)
        .map((page) => (
          <ListItemButton
            key={page.title}
            to={page.path}
            component={NavLink}
            css={navLinkStyle}
            className={`
              ${page.title === 'CurrentAlert' && alert ? 'alert' : ''}
              ${page.subPath === paramPath ? 'active' : ''}
          `}
            onClick={closeWhenMobile}
            style={{ paddingRight: isSmallScreen ? 0 : theme.spacing(5.75) }}
          >
            <StyledMenuIcon drawerOpen={drawerOpen}>
              {React.createElement(page.icon)}
            </StyledMenuIcon>
            {page.title === 'CurrentAlert' ? (
              <StyledBadge
                badgeContent={alert}
                alert={alert}
                drawerOpen={drawerOpen}
              >
                <ListItemText
                  primary={
                    <Typography css={menuTypographyStyle}>
                      {intl.formatMessage({
                        id: `pages.${page.title}.title`,
                      })}
                    </Typography>
                  }
                />
              </StyledBadge>
            ) : (
              <ListItemText
                primary={
                  <Typography css={menuTypographyStyle}>
                    {intl.formatMessage({
                      id: `pages.${page.title}.title`,
                    })}
                  </Typography>
                }
              />
            )}
          </ListItemButton>
        ))}
    </Box>
  );
};

export default MenuList;

function ReportIcon() {
  return <_ReportIcon sx={{ color: 'primary.main' }} />;
}
